body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Orbitron";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 0 100px;
  /* bottom = footer height */
  padding: 25px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

footer {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  overflow: hidden;
}

html {
  position: relative;
  min-height: 100%;
}